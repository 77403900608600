<template>
    <div><span class="registry_fields_label"></span>
        <el-select
            v-model="local.selectedItems"
            clearable
            multiple
            @clear="local.selectedItems = multiple ? [] : null;$emit('input', local.selectedItems)"
            @change="$emit('input', local.selectedItems)"
            style="width:780px;"
        >
            <el-option
                v-for="(item) in local.fields"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
        </el-select>
    </div>
</template>
<script>
export default {
  props: ['model'],
  name: 'AddRegularProjectsCmp',
  data () {
    return {
      local: this.model
    }
  },
  watch: {
    local () {
      this.model = this.local
    },
    model () {
      this.local = this.model
    }
  }
}
</script>

<style scoped>

</style>
