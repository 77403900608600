import BasePlugin from '../BasePlugin'
import AddRegularProjectsCmp from './AddRegularProjectsCmp'

export default class AddRegularProjects extends BasePlugin {
  async execute () {
    const h = this.context.$createElement
    let values = {
      fields: [],
      selectedItems: []
    }
    let date = this.context.getModel().attr_889_
    let programId = this.context.getModel().id
    this.context.$http.get(`${this.context.$config.api}/registryservice/plugins/execute/InvestmentProgram/GetListOfProjectsQuery?programId=${programId}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      let listItems = Object.values(response.data.list)
      values.fields = response.data.list
      if (Object.values(response.data.selected).length === 0) {
        listItems.forEach(function(item) {
          values.selectedItems.push(
            item.id
          )
        })
      }
      Object.values(response.data.selected).forEach((item) => {
        console.log(item.id)
        listItems.forEach(function (item2) {
          if (item.name === item2.name) {
            values.selectedItems.push(
              item2.id // need add break
            )
          }
        })
      })
    })
    this.context.$msgbox({
      title: 'Выбрать копируемые заявки',
      message: h('p', { key: Math.random() }, [
        h(AddRegularProjectsCmp, { props: { model: values } })
      ]),
      showCancelButton: false,
      confirmButtonText: 'Отправить',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          let payload = {
            'programId': this.context.getModel().id,
            'requestsIds': values.selectedItems !== null ? values.selectedItems : []
          }
          this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/InvestmentProgram/CopyInvestmentRequestsIntoProgramCommand`,
            payload,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          ).then((response) => {
            done()
            this.context.$message({
              type: 'info',
              message: 'Заявки скопированы'
            })
          })
        } else {
          done()
        }
      }
    }).then(action => {

    })
    // костыль, но в плагин запихать ширину окна пока неясно как
    document.getElementsByClassName('el-message-box')[0].style.width = '840px'
  }
}
